import { Link } from "gatsby";
import React from "react";
import blogImg from "../../../images/blog_inn.png";
import blogImg2 from "../../../images/The Selby Team_When Is the Best Time to Buy a House_.jpg";
import blogImg3 from "../../../images/The Selby Team_Why Waiting for Home Prices to Drop Could be a Mistake.jpg";
import blogImg1 from "../../../images/The Selby Team_Tips for Buying a Home During a Recession.jpg";
import blogImg4 from "../../../images/The Selby Team_Top 10 Tips to Sell Your Home for Top Dollar.jpg";
import blogImg5 from "../../../images/The Selby Team_10 Reasons Why You Should Live in San Diego’s University Heights in 2023.jpg";
import blogImg6 from "../../../images/The Selby Team_Best Places to Live in San Diego.jpg";
import blogImg7 from "../../../images/The Selby Team_5 Tips for Finding the Best Mortgage Lender.jpg";
import blogImg8 from "../../../images/The Selby Team_5 Reasons Why San Diego’s Bankers Hill Is a Great Place to Live.jpg";
import blogImg9 from "../../../images/The Selby Team_The Do’s and Don’ts of Turning Your Primary Residence Into an Investment Property.jpg";
import blogImg10 from "../../../images/The Selby Team_The Different Types of Home Loans_ Which One Is Right for You_.jpg";
import blogImg11 from "../../../images/The Selby Team_How to Beat Rising Interest Rates as a Home Buyer.jpg";

import blogImg12 from "../../../images/The Selby Team_10 Things to Consider Before You Build an ADU.jpg";
import blogImg13 from "../../../images/The Selby Team_First Time Home Buyer_ What You Need to Know.jpg";
import blogImg14 from "../../../images/The Selby Team_Real Estate_ How To Capitalize On High Interest Rates.jpg";
import blogImg15 from "../../../images/The Selby Team_VA Home Loan Advantages & Requirements.jpg";
import blogImg16 from "../../../images/The Selby Team_Living In Bankers Hill- What To Know Before Buying A Home.jpg";
import blogImg17 from "../../../images/The Selby Team_Tips on How to Sell Your Rental_Investment Property.jpg";
import blogImg18 from "../../../images/The Selby Team_What Makes North Park an Attractive Real Estate Market.jpg";
import blogImg19 from "../../../images/The Selby Team_The Impact Of Interest Rates On San Diego's Real Estate Market & How To Navigate.jpg";
import blogImg20 from "../../../images/The Selby Team_A Checklist For Selling Your San Diego Home Quickly.jpg";

import blogImg21 from "../../../images/The Selby Team_A Guide To The Unique Homes In University Heights.jpg";
import blogImg22 from "../../../images/The Selby Team_Investing In Rental Properties In San Diego_ What You Need To Know.jpg";
import blogImg23 from "../../../images/The Selby Team_Buying Vs. Renting In Normal Heights_ A Comparative Study.jpg";
import blogImg24 from "../../../images/The Selby Team_Green Homes In San Diego What Buyers Should Know.jpg";
import blogImg25 from "../../../images/The Selby Team_South Park A Hidden Gem In San Diego's Real Estate Market.jpg";
import blogImg26 from "../../../images/The Selby Team_What Has Changed in the Approval Process for Buying a Home.jpg";
import blogImg27 from "../../../images/The Selby Team_How to Stage San Diego Home to Sell Quickly.jpg";
import blogImg28 from "../../../images/The Selby Team_How to Choose a Real Estate Agent in San Diego.jpg";

import { StaticImage } from "gatsby-plugin-image";
import { blogList } from "../../../data/blogs";
// import Img from "gatsby-image"

const RealStateCardData = [
  {
    img: blogImg28,
    title: "Agents",
    descrip: "How to Choose a Real Estate Agent in San Diego",
    url: "/how-to-choose-a-real-estate-agent-in-san-diego/",
  },
  {
    img: blogImg25,
    title: "South Park",
    descrip: "A Hidden Gem in San Diego’s Real Estate Market",
    url: "/south-park-a-hidden-gem-in-san-diego-real-estate-market/",
  },
  {
    img: blogImg24,
    title: "Green Homes",
    descrip: "Green Homes in San Diego: What Buyers Should Know",
    url: "/green-homes-in-san-diego-what-buyers-should-know/",
  },
  {
    img: blogImg27,
    title: "Buying",
    descrip: "How To Stage Your Home to Sell Quickly",
    url: "/how-to-stage-your-san-diego-home/",
  },
  {
    img: blogImg26,
    title: "Buying",
    descrip: "What Has Changed in the Approval Process for Buying a Home?",
    url: "/what-has-changed-in-the-approval-process-for-buying-a-home/",
  },
  {
    img: blogImg23,
    title: "Buyer",
    descrip: "Buying Vs. Renting In Normal Heights: A Comparative Study",
    url: "/buying-vs-renting-in-normal-heights/",
  },
  {
    img: blogImg22,
    title: "Investor",
    descrip: "Investing In Rental Properties In San Diego",
    url: "/investing-in-rental-properties-in-san-diego/",
  },
  {
    img: blogImg21,
    title: "Buyer",
    descrip: "A Guide to the Unique Homes in University Heights",
    url: "/a-guide-to-the-unique-homes-in-university-heights/",
  },
  {
    img: blogImg20,
    title: "Seller",
    descrip: "A Checklist for Selling Your San Diego Home Quickly",
    url: "/checklist-for-selling-your-san-diego-home-quickly/",
  },
  {
    img: blogImg19,
    title: "Buyer",
    descrip: "The Impact Of Interest Rates On San Diego's Real Estate Market & How To Navigate",
    url: "/interest-rate-impact-san-diego-real-estate/",
  },
  {
    img: blogImg18,
    title: "Buyer",
    descrip: "What Makes San Diego’s North Park an Attractive Real Estate Market?",
    url: "/north-park-san-diego-housing-market/",
  },
  {
    img: blogImg14,
    title: "Seller",
    descrip: "Real Estate: How to Capitalize on High Interest Rates",
    url: "/capitalize-high-interest-rates-real-estate/",
  },
  {
    img: blogImg16,
    title: "Buyer",
    descrip: "Living in Bankers Hill: What to Know Before Buying a Home",
    url: "/living-in-bankers-hill-san-diego/",
  },
  {
    img: blogImg12,
    title: "ADU",
    descrip: "10 Things to Consider Before You Build an ADU",
    url: "/things-to-consider-before-you-build-an-adu/",
  },
  {
    img: blogImg13,
    title: "Buyer",
    descrip: "First Time Home Buyer? What You Need to Know",
    url: "/first-time-home-buyer-what-to-know/",
  },
  {
    img: blogImg15,
    title: "VA Home Loan Buyer",
    descrip: "VA Home Loan Advantages & Requirements",
    url: "/va-home-loan-advantages-requirements/",
  },
  {
    img: blogImg17,
    title: "Seller",
    descrip: "Tips on How to Sell Your Rental/Investment Property",
    url: "/tips-on-how-to-sell-your-rental-investment-property/",
  },
  {
    img: blogImg5,
    title: "Buying",
    descrip: "10 Reasons Why You Should Live in San Diego’s University Heights in 2023",
    url: "/reasons-to-live-in-san-diego-university-heights/",
  },

  {
    img: blogImg4,
    title: "Selling",
    descrip: "Top 10 Tips to Sell Your Home for Top Dollar",
    url: "/10-tips-to-sell-your-home/",
  },
  {
    img: blogImg2,
    title: "Buying",
    descrip: "When Is the Best Time to Buy a House?",
    url: "/when-is-the-best-time-to-buy-a-house/",
  },
  {
    img: blogImg3,
    title: "Buying",
    descrip: "Why Waiting for Home Prices to Drop Could Be a Mistake",
    url: "/waiting-for-home-prices-to-drop-could-be-a-mistake/",
  },

  {
    img: blogImg6,
    title: "Buyer",
    descrip: "Best Places to Live in San Diego",
    url: "/best-places-to-live-in-san-diego/",
  },
  {
    img: blogImg7,
    title: "Buyer",
    descrip: "5 Tips for Finding the Best Mortgage Lender",
    url: "/tips-to-find-best-mortgage-lender/",
  },
  {
    img: blogImg8,
    title: "Buyer",
    descrip: "5 Reasons Why San Diego’s Bankers Hill Is a Great Place to Live",
    url: "/bankers-hill-is-great-place-to-live/",
  },
  {
    img: blogImg9,
    title: "Seller",
    descrip: "The Do’s and Don’ts of Turning Your Primary Residence Into an Investment Property",
    url: "/turning-primary-residence-into-investment-property/",
  },
  {
    img: blogImg10,
    title: "Home Loans",
    descrip: "The Different Types of Home Loans: Which One Is Right for You?",
    url: "/different-types-of-home-loans/",
  },
  {
    img: blogImg11,
    title: "Buyer",
    descrip: "How to Beat Rising Interest Rates as a Home Buyer",
    url: "/beat-rising-interest-rates-home-buyer/",
  },
  // {
  //   img: blogImg,
  //   title: "Optional Category",
  //   descrip: "Super Awesome Blog Title Goes Here Lorem Ipsum Dolor Sit Amet",
  // },

  // {
  //   img: blogImg,
  //   title: "Optional Category",
  //   descrip: "Super Awesome Blog Title Goes Here Lorem Ipsum Dolor Sit Amet",
  // },
  // {
  //   img: blogImg,
  //   title: "Optional Category",
  //   descrip: "Super Awesome Blog Title Goes Here Lorem Ipsum Dolor Sit Amet",
  // },

  // {
  //   img: blogImg,
  //   title: "Optional Category",
  //   descrip: "Super Awesome Blog Title Goes Here Lorem Ipsum Dolor Sit Amet",
  // },
  // {
  //   img: blogImg,
  //   title: "Optional Category",
  //   descrip: "Super Awesome Blog Title Goes Here Lorem Ipsum Dolor Sit Amet",
  // },

  // {
  //   img: blogImg,
  //   title: "Optional Category",
  //   descrip: "Super Awesome Blog Title Goes Here Lorem Ipsum Dolor Sit Amet",
  // },
];

const RealStateSuccess = () => {
  //   const data = useStaticQuery(graphql`
  //   query {

  //     placeholderImage: file(relativePath: { eq: "blog_inn.png" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 300) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)
  return (
    <>
      <div className="pt-[130px] pb-[127px] md:pt-[170px] md:pb-[70px] sm:pt-[30px]">
        <div className="container">
          <h1 className="text-d-2xl font-IbarraRealNova font-[700] mb-[80px] sm:text-d-xl sm:mb-[30px]">
            Selby Secrets to Real Estate{" "}
            <span className="text-d-3xl sm:text-d-xl font-[500] font-Quicksand"> Success</span>
          </h1>

          <div className="flex gap-[40px] md:flex-col">
            <div className="2xs_extar:px-[20px] xs:px-[0px]">
              <Link to={blogList[0].url}>
                <img
                  // src="../../../images/The Selby Team_Real Estate_ How To Capitalize On High Interest Rates.jpg"
                  src={blogList[0].img}
                  alt="blog"
                  className="mb-[24px] md:w-full max-w-[768px]"
                />
              </Link>
              <p className="mb-[16px] text-d-2lg mb-[8px] text-[#B2B2B2] uppercase font-Quicksand">
                {blogList[0].category}
              </p>
              <Link to={RealStateCardData[0].url}>
                <p className="text-sm-5lg font-Quicksand font-[600] sm:text-d-4lg">
                  {blogList[0].title}
                </p>
              </Link>
            </div>
            <div className="flex flex-col gap-[60px] md:gap-[40px] md:flex-row 2xs_extar:flex-col 2xs_extar:px-[20px] 2xs_extar:gap-[40px] xs:px-[0px]">
              {blogList.slice(1, 3).map((value) => {
                return (
                  <>
                    <div className="max-w-[373px] w-full md:max-w-[100%] md:w-full">
                      {/* <div className="mb-[24px] md:w-full">
                    <Img fluid={data.placeholderImage.childImageSharp.fluid}  />

                    </div> */}
                      <Link to={value.url}>
                        <img src={value.img} alt="blogimg" className="mb-[28px] md:w-full" />
                      </Link>
                      <p className="text-d-2lg mb-[8px] text-[#B2B2B2] font-Quicksand uppercase font-[700]">
                        {value.category}
                      </p>
                      <Link to={value.url}>
                        <p className="text-sm-5lg font-Quicksand font-[600] sm:text-d-4lg mb-[8px]">
                          {value.title}
                        </p>
                      </Link>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <div className="pt-[60px] 2xs_extar:pt-[40px]">
            <div className="flex flex-wrap gap-x-[40px] gap-y-[60px] 2xs_extar:px-[20px] 2xs_extar:gap-y-[40px] xs:px-[0px]">
              {blogList.slice(3).map((value) => {
                return (
                  <>
                    <div className="" id="blogCard">
                      {/* <div className="mb-[24px] md:w-full">
                    <Img fluid={data.placeholderImage.childImageSharp.fluid} />

                    </div> */}
                      <Link to={value.url}>
                        <img src={value.img} alt="blogimg" className="mb-[28px] md:w-full" />
                      </Link>
                      <p className="text-d-2lg mb-[8px] text-[#B2B2B2] font-Quicksand uppercase font-[700]">
                        {value.category}
                      </p>
                      <Link to={value.url}>
                        <p className="text-sm-5lg font-Quicksand font-[600] sm:text-d-4lg mb-[8px]">
                          {value.title}
                        </p>
                      </Link>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RealStateSuccess;
